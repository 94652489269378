export const SITE_TYPES = Object.freeze({
    DOMAINE: "Domaine",
    PARCELLE: "Parcelle",
    SOUS_PARCELLE: "Sous parcelle",
    PLACETTE: "Placette",
    PIED: "Pied",
    GRAPPE: "Grappe",
    CHAI: "Chai",
    SOUS_CHAI: "Sous chai",
    CUVE: "Cuve",
    FUT: "Fut",
    BOUTEILLE: "Bouteille",
    ALARME: "Alarme",
});

export const SITE_TYPES_VALUES = Object.values(SITE_TYPES);
export const DEFAULT_SITE_TYPE = SITE_TYPES_VALUES[1];

export const SITE_DEFAULT = {
    client_id: null,
    name: "",
    address: "",
    gps: "",
    is_allow_input_value: 1,
    is_allow_year_compare: 1,
    description: "",
    lagoon_volume: "",
    logo: null,
    logo_url: "",
    sensors: [],
    comment: "",
    installation_date: "",
    visits: [],
    ftp_host: "",
    ftp_user: "",
    ftp_pwd: "",
    ftp_path: "",
    categories: {
      names : []
    },
    events: {
      names: []
    },

    relations: {
        children: [],
        parents: [],
    },
    users: [],
    date_formats: {
        DefaultFormat: '',
        Overrides: []
    },
    type: DEFAULT_SITE_TYPE,
    remove_logo : true,
}

export default SITE_TYPES;
