<template>
    <div>
        <v-toolbar fixed app color="grey lighten-4" dense>
            <v-toolbar-title class="text-uppercase">{{ mode === 'CO2' ? "Sécurité" : "Suivi Consommation Electrique" }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn small :color="mode === 'CO2' ? 'primary' : ''" @click="mode = 'CO2'">CO2</v-btn>
            <v-btn small :color="mode === 'ELEC' ? 'primary' : ''" @click="mode = 'ELEC'">Compteurs Elec</v-btn>
        </v-toolbar>
        <div style="height: 64px;"></div>

        <div v-if="securityReady">
            <v-row v-for="client in profile.clients" :key="client.id">
                <v-col cols="12" style="position: relative;">
                    <overlay ref="overlay" :isWarning="isFullyWarn" v-if="mode === 'CO2'"></overlay>
                    <v-card class="mb-5">
                        <v-card-title>{{ client.name }}</v-card-title>

                        <div v-if="client.id == 6 && !$isMobile() && mode === 'CO2'" :style="{position: 'relative', width: $isMobile() ? '300px': 'max-content', height: $isMobile() ? '300px': 'auto', overflow: 'hidden', border: '4px solid gray'}">
                            <ev-plan :arrayed-sensor="filterArray(profile.sites, client.id)" :CO2Sites="CO2Sites" :movingSize="$isMobile() ? 300 : 0"></ev-plan>
                        </div>

                        <v-card-text v-if="!$isMobile()">
                            <v-row>
                                <v-col cols="12" :sm="mode === 'CO2' ? 6 : 12" :md="mode === 'CO2' ? 4 : 12" v-for="sensor in filterArray(profile.sites, client.id)" :key="sensor.id">
                                    <!-- Ton bloc site + capteurs reste identique -->
                                    <v-card class="ma-2" elevation="2" color="grey lighten-4" @click="$router.push({ name: 'Dashboard', params: { site_id: sensor.id }, })">
                                        <v-card-title class="headline text-center mx-auto" style="border-bottom: 1px solid; padding-bottom: 16px;">{{ sensor.name }}</v-card-title>
                                        <v-row no-gutters class="pa-3">
                                            <v-col :cols="mode === 'CO2' ? 12 : 12" :md="mode === 'CO2' ? 6 : 3" v-for="(co2s, idx) in sortedCO2Sites(sensor.id)" :key="co2s[1].name + idx">
                                                <v-card class="mb-2" outlined tile>
                                                    <v-card-text>
                                                        <div class="d-flex flex-column align-center">
                                                            <v-chip v-if="!co2s[1].disabled" :color="co2s[1].value > co2s[1].max ? 'red lighten-2' : 'green lighten-2'" dark class="mt-2">{{ co2s[1].name }}</v-chip>
                                                            <v-row justify="center" class="my-2">
                                                                <v-icon large v-if="co2s[1].disabled" color="amber darken-2">report_off</v-icon>
                                                                <v-icon large v-else-if="co2s[1].value > co2s[1].max" color="red darken-1">warning</v-icon>
                                                                <v-icon large v-else color="green darken-1">check_circle</v-icon>
                                                            </v-row>
                                                            <v-list dense class="pa-0">
                                                                <v-list-item v-if="!co2s[1].disabled" class="px-0" :style="{'background-color': co2s[1].value > co2s[1].max ? '#FA6E95' : '#81C784'}">
                                                                    <v-list-item-content class="px-1">
                                                                        <div class="text-center">
                                                                            <v-list-item-title class="subtitle-2">Dernière mise à jour</v-list-item-title>
                                                                            <v-list-item-subtitle>{{ co2s[1].value }} {{ mode === 'CO2' ? "ppm" : "kWh" }} le {{ co2s[1].date }}</v-list-item-subtitle>
                                                                        </div>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item class="px-0" :style="{'background-color': 'transparent'}" v-if="mode === 'CO2'">
                                                                    <v-list-item-content class="px-1" v-if="!co2s[1].disabled && co2s[1].hasMaxValue" :style="{'background-color': '#FA6E95', 'width': '100%'}">
                                                                        <div class="text-center">
                                                                            <v-list-item-title class="subtitle-2">Dernier dépassement</v-list-item-title>
                                                                            <v-list-item-subtitle>{{ co2s[1].valueMax }} ppm le {{ co2s[1].dateMax }}</v-list-item-subtitle>
                                                                        </div>
                                                                    </v-list-item-content>
                                                                    <v-list-item-content class="px-1" v-else :style="{'width': '100%'}">
                                                                        <div class="text-center">
                                                                            <v-list-item-title class="subtitle-2">Dernier dépassement</v-list-item-title>
                                                                            <v-list-item-subtitle>N/A</v-list-item-subtitle>
                                                                        </div>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-text v-else>
                            <v-row>
                                <v-col cols="12" v-for="sensor in filterArray(profile.sites, client.id)" :key="sensor.id">
                                    <!-- même affichage mobile -->
                                    <v-card class="ma-2 elevation-2" color="grey lighten-4" @click="$router.push({ name: 'Dashboard', params: { site_id: sensor.id }, })">
                                        <v-card-title class="headline text-center mx-auto" style="border-bottom: 1px solid; padding-bottom: 16px;">{{ sensor.name }}</v-card-title>
                                        <v-row no-gutters class="pa-3">
                                            <v-col cols="12" md="6" v-for="(co2s, idx) in sortedCO2Sites(sensor.id)" :key="co2s[1].name + idx">
                                                <v-card class="mb-2" outlined tile>
                                                    <v-card-text>
                                                        <div class="d-flex flex-column align-center">
                                                            <v-chip v-if="!co2s[1].disabled" :color="co2s[1].value > co2s[1].max ? 'red lighten-2' : 'green lighten-2'" dark class="mt-2">{{ co2s[1].name }}</v-chip>
                                                            <v-row justify="center" class="my-2">
                                                                <v-icon large v-if="co2s[1].disabled" color="amber darken-2">report_off</v-icon>
                                                                <v-icon large v-else-if="co2s[1].value > co2s[1].max" color="red darken-1">warning</v-icon>
                                                                <v-icon large v-else color="green darken-1">check_circle</v-icon>
                                                            </v-row>
                                                            <v-list dense class="pa-0">
                                                                <v-list-item v-if="!co2s[1].disabled" class="px-0" :style="{'background-color': co2s[1].value > co2s[1].max ? '#FA6E95' : '#81C784'}">
                                                                    <v-list-item-content class="px-1">
                                                                        <div class="text-center">
                                                                            <v-list-item-title class="subtitle-2">Dernière mise à jour</v-list-item-title>
                                                                            <v-list-item-subtitle>{{ co2s[1].value }} {{ mode === 'CO2' ? "ppm" : "kWh" }} le {{ co2s[1].date }}</v-list-item-subtitle>
                                                                        </div>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                                <v-list-item class="px-0" :style="{'background-color': 'transparent'}" v-if="mode === 'CO2'">
                                                                    <v-list-item-content class="px-1" v-if="!co2s[1].disabled && co2s[1].hasMaxValue" :style="{'background-color': '#FA6E95', 'width': '100%'}">
                                                                        <div class="text-center">
                                                                            <v-list-item-title class="subtitle-2">Dernier dépassement</v-list-item-title>
                                                                            <v-list-item-subtitle>{{ co2s[1].valueMax }} ppm le {{ co2s[1].dateMax }}</v-list-item-subtitle>
                                                                        </div>
                                                                    </v-list-item-content>
                                                                    <v-list-item-content class="px-1" v-else :style="{'width': '100%'}">
                                                                        <div class="text-center">
                                                                            <v-list-item-title class="subtitle-2">Dernier dépassement</v-list-item-title>
                                                                            <v-list-item-subtitle>N/A</v-list-item-subtitle>
                                                                        </div>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </div>
                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { buildSortSizeQuery, buildSortSizeMoreQuery } from "../elastic/queries";
import evPlan from "./evPlan.vue";
import overlay from "./Overlay.vue";

export default {
    components: { evPlan, overlay },
    data() {
        return {
            profile: this.$store.state.profile.user,
            securityReady: false,
            isFullyWarn: false,
            CO2Sites: [],
            mode: 'CO2'
        };
    },
    computed: {
        sortedCO2Sites() {
            return (sensorId) => {
                const entries = Object.entries(this.CO2Sites[sensorId] || {});
                entries.sort((a, b) => a[1].name.localeCompare(b[1].name));
                return entries;
            };
        }
    },
    mounted() {
        this.updateUserProfile();
    },
    watch: {
        mode() {
            this.CO2Sites = {};
            this.securityReady = false;
            this.updateUserProfile();
        }
    },
    methods: {
        roundDecimal(nombre, precision = 2) {
            let tmp = Math.pow(10, precision);
            return Math.round(nombre * tmp) / tmp;
        },
        updateUserProfile() {
            this.$store.dispatch('profileRequest').then(() => {
                this.profile = this.$store.state.profile.user;
                let key = this.mode === 'CO2' ? 'securityCO2_mesure' : 'securityElec_mesure';
                let condition = this.mode === 'CO2' ? 'is_securityCO2' : 'is_securityElec';

                let newArray = this.profile.sites.filter((s) => s[condition] === 1);

                newArray.forEach((site) => {
                    if (site[key] !== null && site[key] !== "null") {
                        let data = JSON.parse(site[key]);
                        data.forEach((sensorMesure) => {
                            let max = (!isNaN(sensorMesure.max)) ? sensorMesure.max : 9999999999999;
                            this.searchForLastValue(site.id, site.client_id, sensorMesure.csv_column, max, sensorMesure.display_name, { x: sensorMesure.X, y: sensorMesure.Y });
                        });
                    } else {
                        this.$set(this.CO2Sites, site.id, [{ disabled: true }]);
                    }
                });

                this.securityReady = true;
            });
        },
        searchForLastValue(site_id, client_id, mesure, max, display_name, position){

            let elastic_winery_mesures_index = "winery_mesures_" + client_id;

            let elasticQuery = buildSortSizeQuery(
                site_id,
                null,
                "desc",
                mesure
            );

            this.$store
                .dispatch("elasticSearchRequest", {
                    index: elastic_winery_mesures_index,
                    body: elasticQuery,
                    size: 1,
                    site: site_id,
                }).then((result) => {
                if(result.data.hits.hits.length > 0){

                    let currentSite = this.profile.sites.filter((arrayF) => arrayF.id === site_id)[0];

                    let dateRes = result.data.hits.hits[0].sort[0].toString().length === 13 ? (Math.floor(result.data.hits.hits[0].sort[0] / 1000) - 3600) * 1000 : result.data.hits.hits[0].sort[0];
                    let actualDate = new Date(dateRes);
                    actualDate.setHours(actualDate.getHours() + currentSite["time_offset"]);

                    let newCO2 = {disabled: false, hasMaxValue: false, valueMax: 0, dateMax: "", value: this.roundDecimal(parseFloat(result.data.hits.hits[0]._source[mesure]), 3), date: actualDate.toLocaleDateString('fr-fr', {timeZone: "Europe/Paris", year:"2-digit", month:"numeric", day:"numeric", hour:"numeric", minute:"numeric", second:"numeric"}), max: parseFloat(max), name: display_name, position: position};

                    if(newCO2['value'] > newCO2['max'] && !this.isFullyWarn){
                        this.isFullyWarn = true;
                    }

                    let elasticQueryNew = buildSortSizeMoreQuery(
                        site_id,
                        null,
                        "desc",
                        mesure,
                        max
                    );

                    this.$store
                        .dispatch("elasticSearchRequest", {
                            index: elastic_winery_mesures_index,
                            body: elasticQueryNew,
                            size: 1,
                            site: site_id,
                        }).then((resulting) => {
                        if(resulting.data.hits.hits.length > 0){

                            let dateResMax = resulting.data.hits.hits[0].sort[0].toString().length === 13 ? (Math.floor(resulting.data.hits.hits[0].sort[0] / 1000) - 3600) * 1000 : resulting.data.hits.hits[0].sort[0];
                            let actualMaxDate = new Date(dateResMax);
                            actualMaxDate.setHours(actualMaxDate.getHours() + currentSite["time_offset"]);

                            newCO2['valueMax'] = parseFloat(resulting.data.hits.hits[0]._source[mesure]);
                            newCO2['dateMax'] = actualMaxDate.toLocaleDateString('fr-fr', {timeZone: "Europe/Paris", year:"2-digit", month:"numeric", day:"numeric", hour:"numeric", minute:"numeric", second:"numeric"});

                            if(newCO2['valueMax'] >= newCO2['max']){
                                newCO2['hasMaxValue'] = true;
                            }

                        }

                        if(!this.CO2Sites[site_id]){
                            this.$set(this.CO2Sites, site_id, [newCO2]);
                        } else {
                            let ref = this.CO2Sites[site_id];
                            let newCO2Item = [...ref, newCO2];
                            this.$set(this.CO2Sites, site_id, newCO2Item);
                        }

                    }).catch((err) => {
                        console.log(err);
                    });
                } else {
                    if(!this.CO2Sites[site_id]){
                        this.$set(this.CO2Sites, site_id, [{disabled: true}]);
                    } else {
                        let ref = this.CO2Sites[site_id];
                        this.$set(this.CO2Sites, site_id, [...ref, {disabled: true}]);
                    }
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        filterArray(arrayF, filterVar) {
            let key = this.mode === 'CO2' ? 'is_securityCO2' : 'is_securityElec';
            return arrayF.filter((a) => a.client_id === filterVar && a[key] === 1);
        }
    }
};
</script>

<style>
@keyframes warningAnim {
  from { opacity: 1; }
  to { opacity: 0; }
}
.blink {
  animation: warningAnim 1s infinite alternate;
}
.top_border {
  position: relative;
}
.top_border:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: linear-gradient(to right, transparent, black, transparent);
}
</style>
