<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="3">
          <template v-if="!yearCompare">
            <v-menu
              ref="queryFromPicker"
              v-model="queryFromPicker"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="query.from"
                  label="Date début"
                  prepend-icon="event"
                  readonly
                  clearable
                  v-on="on"
                ></v-text-field>
              </template>

              <v-date-picker
                locale="fr-FR"
                v-model="query.from"
                no-title
                @input="queryFromPicker = false"
              ></v-date-picker>
            </v-menu>
            <template v-if="isIntervalAllowEndDate()">
              <v-menu
                ref="queryToPicker"
                v-model="queryToPicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="query.to"
                    label="Date fin"
                    prepend-icon="event"
                    readonly
                    clearable
                    v-on="on"
                  ></v-text-field>
                </template>

                <v-date-picker
                  locale="fr-FR"
                  v-model="query.to"
                  no-title
                  @input="queryFromPicker = false"
                ></v-date-picker>
              </v-menu>
            </template>
            <template v-else>
              <div id="slide">
                <!-- <v-menu
label="Plage d'heures"> -->
                <v-range-slider
                  v-model="timerange"
                  :max="72"
                  :min="0"
                  hide-details
                  class="align-center"
                  thumb-label="always"
                  :thumb-size="24"
                  ticks
                  @click.stop="updateTimeRange"
                >
                </v-range-slider>
              </div>
            </template>
            <v-select
              label="Intervalle"
              :items="intervalChooser"
              item-text="label"
              item-value="value"
              v-model="query.interval"
            ></v-select>
            <slot></slot>
          </template>

          <template v-else>
            <v-select
              :items="YEARS"
              v-if="!yearCompareUnique"
              v-model="query.yearCompareQuery.years"
              @input="query.yearCompareQuery.update = 1"
              item-value="id"
              item-text="name"
              multiple
              chips
            ></v-select>
            <v-select
              :items="YEARS"
              v-else
              v-model="query.yearCompareQuery.uniqueYear.year"
              @input="query.yearCompareQuery.update = 1"
              item-value="id"
              item-text="name"
              chips
            ></v-select>
            <v-slider
              v-model="query.yearCompareQuery.uniqueYear.count"
              v-if="yearCompareUnique"
              @change="query.yearCompareQuery.update = 1; queryDisableUpdate = true;"
              :min="0"
              :max="25"
              :step="1"
              thumb-label="always"
            ></v-slider>
            <v-container>
              <v-layout>
                <v-row v-if="!yearCompareUnique">
                  <v-col cols="4" v-for="(id) in query.yearCompareQuery.years" :key="id">
                    <caption>{{ id }}</caption>
                    <v-menu
                      v-model="menu['f_' + id]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="fromYearCompareQueryFromDisp[id]"
                          label="Jour début"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="query.yearCompareQuery.from[id]"
                        :masks="masks"
                        show-adjacent-months
                        @input="queryFromPicker = false; query.yearCompareQuery.update = 1;"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-menu
                      v-model="menu['t_' + id]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="fromYearCompareQueryToDisp[id]"
                          label="Jour Fin"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="query.yearCompareQuery.to[id]"
                        :masks="masks"
                        show-adjacent-months
                        @input="queryFromPicker = false; query.yearCompareQuery.update = 1;"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="4" v-for="(id) in query.yearCompareQuery.uniqueYear.count" :key="id">
                    <caption>{{ query.yearCompareQuery.uniqueYear.year }}</caption>
                    <v-menu
                      v-model="menu['fu_' + query.yearCompareQuery.uniqueYear.year + '_' + id]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="fromYearCompareQueryFromUniqueDisp[query.yearCompareQuery.uniqueYear.year + '_' + id]"
                          label="Jour début"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="query.yearCompareQuery.fromUnique[query.yearCompareQuery.uniqueYear.year + '_' + id]"
                        :masks="masks"
                        show-adjacent-months
                        @input="queryFromPicker = false; /*query.yearCompareQuery.update = 1;*/"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-menu
                      v-model="menu['tu_' + query.yearCompareQuery.uniqueYear.year + '_' + id]"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          :value="fromYearCompareQueryToUniqueDisp[query.yearCompareQuery.uniqueYear.year + '_' + id]"
                          label="Jour Fin"
                          prepend-icon="event"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="query.yearCompareQuery.toUnique[query.yearCompareQuery.uniqueYear.year + '_' + id]"
                        :masks="masks"
                        show-adjacent-months
                        @input="queryFromPicker = false; /*query.yearCompareQuery.update = 1;*/"
                      >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-layout>
            </v-container>
            <v-select
              label="Intervalle"
              :items="intervalChooserYearCompare"
              item-text="label"
              item-value="value"
              v-model="query.yearCompareQuery.interval"
            ></v-select>
            <v-switch v-model="yearCompareUnique" label="Comparaison multi-période">

            </v-switch>
          </template>

          <template v-if="site.type === 'Domaine'">
            <v-select
              v-for="filterName in filtersName"
              :key="filterName"
              :label="getFiltreLabel(filterName)"
              :items="filtersData[filterName]"
              v-model="filters[filterName]"
              multiple
              chips
            ></v-select>
          </template>
          <br />
          <template v-if="yearCompare && site.is_allow_nearest_years && yearCompareUnique">
            <h3>Années les plus proches</h3>
            <v-select
              label="Année de référence"
              :items="YEARS"
              v-model="nearestYearQuery.referenceYear"
              item-value="id"
              item-text="name"
            ></v-select>
            <v-select
              label="Cépage de référence"
              :items="filtersData.cepages"
              v-model="nearestYearQuery.cepage"
              item-value="id"
              item-text="name"
            ></v-select>
            <v-switch
              v-model="nearestYearQuery.hasPrecipitations"
              label="Cumul précipitations"
              :true-value="parseInt(1)"
              :false-value="parseInt(0)"
              flat
              inset
            ></v-switch>
            <v-switch
              v-model="nearestYearQuery.hasTmax"
              label="Cumul température max"
              :true-value="parseInt(1)"
              :false-value="parseInt(0)"
              flat
              inset
            ></v-switch>
            <v-switch
              v-model="nearestYearQuery.hasTmoy10"
              label="Cumul température moy > 10°"
              :true-value="parseInt(1)"
              :false-value="parseInt(0)"
              flat
              inset
            ></v-switch>
            <template v-if="site.events && site.events.names">
              <v-select
                label="Evénements"
                :items="site.events.names"
                v-model="nearestYearQuery.events"
                item-value="id"
                item-text="name"
                multiple
                chips
              ></v-select>
            </template>
            <v-btn
              color="primary"
              depressed
              class="align-self-center ml-4 dashboard-btn"
              @click.stop="computeNearestYears"
              :loading="loadingNearestYears"
              :disabled="!this.canComputeNearestYears()"
            >
              <slot> Valider </slot>
            </v-btn>
          </template>
        </v-col>
        <v-col cols="12" md="9">
          <v-tabs v-model="activeTab" show-arrows>
            <v-tabs-slider></v-tabs-slider>
            <v-btn
              v-if="$store.state.profile.user.role != 'user'"
              depressed
              color="primary"
              class="align-self-center mr-4"
              @click.stop="createVisualizationModal = true"
            >
              <v-icon>addchart</v-icon>
            </v-btn>
            <v-tab
              :href="'#tab-' + visualization.id"
              v-for="visualization in site.visualizations.filter((viz) => (viz.component == null) | (viz.component!=null && !viz.component.includes('RadarChart')))"
              :key="visualization.id + updateKey"
            >
              {{ visualization.name }}
            </v-tab>

            <v-dialog v-model="createVisualizationModal" content-class="v-dialog--custom">
              <VisualizationForm
                ref="vizCreate"
                :value="emptyVisualization"
                :site="site"
                :clientSites="clientSites"
                :childIdsToName="childIdsToName"
                @created="onVisualizationCreated"
                @cancel="onVisualizationCanceled"
              />
            </v-dialog>
          </v-tabs>
          <v-tabs-items v-model="activeTab" :key="activeTab">
            <v-tab-item
              v-for="(visualization, i) in site.visualizations"
              :key="visualization.id + updateKey"
              :value="'tab-' + visualization.id"
            >
              <v-card flat>
                <v-card-text
                  class="d-flex justify-end"
                  v-if="$store.state.profile.user.role != 'user'"
                >
                  <v-container class="v-btn-toggle justify-end">
                    <v-btn
                      color="primary"
                      depressed
                      small
                      @click.stop="editVisualizationModal = true"
                      >Modifier</v-btn
                    >
                    <v-btn
                        color="primary"
                        depressed
                        small
                        @click.stop="printImage"
                    >Imprimer</v-btn
                    >
                    <v-btn
                      color="red darken-4"
                      dark
                      depressed
                      small
                      @click="deleteVisualization(visualization)"
                      >Supprimer</v-btn
                    >
                    <v-btn
                      dark
                      depressed
                      small
                      @click.stop="updateUser()"
                      color="primary">
                      <v-icon>refresh</v-icon>
                      Refresh Profile
                    </v-btn>
                    <v-dialog v-model="editVisualizationModal" max-width="1000px">
                      <VisualizationForm
                        ref="vizUpdate"
                        v-model="site.visualizations[i]"
                        :site="site"
                        :clientSites="clientSites"
                        :childIdsToName="childIdsToName"
                        @updated="onVisualizationUpdated"
                        @cancel="onVisualizationCanceled"
                        :key="visualization.id"
                      />
                    </v-dialog>
                  </v-container>
                </v-card-text>
                <v-card-text v-if="!yearCompareUnique">
                  <Visualization
                    :site="site"
                    :visualization="visualization"
                    :query="query"
                    :filters="filters"
                    :yearCompare="yearCompare"
                    :yearCompareQuery="query.yearCompareQuery"
                    :uniqueYearCompare="yearCompareUnique"
                    :reload="reload"
                    :childIdsToName="childIdsToName"
                  >
                    <p hidden id="graphName">{{ visualization.name }}</p>
                  </Visualization>
                </v-card-text>
                <v-card-text v-else>
                  <Visualization
                    v-for="ccount in query.yearCompareQuery.uniqueYear.count"
                    :key="ccount"
                    :site="site"
                    :visualization="visualization"
                    :query="query"
                    :filters="filters"
                    :yearCompare="yearCompare"
                    :yearCompareQuery="query.yearCompareQuery"
                    :uniqueYearCompare="yearCompareUnique"
                    :currentYearIndex="ccount"
                    :reload="reload"
                    :childIdsToName="childIdsToName"
                  >
                    <p hidden id="graphName">{{ visualization.name }}</p>
                  </Visualization>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-row>
            <NearestYearsTables :nearestYears="nearestYears" />
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Visualization from "@/components/Visualization";
import VisualizationForm from "@/components/VisualizationForm";
import { convertDatePickerToDDMMstr, generateArrayOfYears } from "@/utils/dates";
import { buildQueryMust } from "@/elastic/queries";
import NearestYearsTables from "@/components/NearestYearsTables";
import VueMoment from "vue-moment";
import Vue from "vue";

const YEARS = generateArrayOfYears();
const YEAR_COMPARE_QUERY_DEFAULT = {
  from: {
    2010: "2010-01-01",
    2011: "2011-01-01"
  },
  to: {
    2010: "2010-12-31",
    2011: "2011-12-31"
  },
  years: ["2010", "2011"],
  interval: "month",
};

Vue.use(require('vue-moment'));

const TIMERANGE_DEFAULT = [0, 48];
const QUERY_DEFAULT = {
  from: Vue.moment().subtract(10, "days").format("YYYY-MM-DD"),
  to: Vue.moment().format("YYYY-MM-DD"),
  timerange: TIMERANGE_DEFAULT,
  interval: "day",
  yearCompareQuery: YEAR_COMPARE_QUERY_DEFAULT,
};

export default {
  components: {
    Visualization,
    VisualizationForm,
    NearestYearsTables,
  },
  props: {
    site: { type: Object, required: true },
    clientSites: { type: Array, required: true },
    childIdsToName: { type: Object, required: true },
    yearCompare: { type: Boolean, required: false, default: false },
    reload: { type: Boolean, required: false },
    updateKey: { type: Number, required: false, default: 0 },
  },
  data() {
    let filtersName = ["parcelles", "cepages", "traitements"];
    let filtersData = {};
    let emptyVisualization = {};
    filtersData.parcelles = [];
    filtersData.cepages = [];
    filtersData.traitements = [
      "Anti excoriose",
      "Anti Mildiou",
      "Anti Oidium",
      "Anti Botytis",
      "Ferti",
      "Insecticide",
      "Adjuvant",
      "Anti Botrytis",
      "Tisane",
    ];

    return {
      timePickerFormat: "HH:mm",
      ready: false,
      filtersName,
      filtersData,
      filters: {},
      yearCompareUnique: false,
      masks: {
        input: "DD-MM",
      },
      query: QUERY_DEFAULT,
      queryFromPicker: false,
      queryToPicker: false,
      timerange: [],
      intervalChooserYearCompare: [
        { value: "day", label: "Jour" },
        { value: "week", label: "Semaine" },
        { value: "month", label: "Mois" },
        { value: "year", label: "Année" },
      ],
      intervalChooser: [
        { value: "data", label: "Données" },
        { value: "minute", label: "Minute" },
        { value: "hour", label: "Heure" },
        { value: "day", label: "Jour" },
        { value: "week", label: "Semaine" },
        { value: "month", label: "Mois" },
        { value: "year", label: "Année" },
      ],
      activeTab: null,
      createVisualizationModal: false,
      editVisualizationModal: false,
      YEARS,
      emptyVisualization,
      nearestYearQuery: {
        referenceYear: "2020",
        cepage: "",
        hasPrecipitations: false,
        hasTmax: false,
        hasTmoy10: false,
        events: [],
      },
      loadingNearestYears: false,
      nearestYears: {},
      queryDisableUpdate: false,
      menu: {},
    };
  },
  mounted() {
    let viz = this.$store.state.profile.user.visualizations;
    if (viz && viz[this.site.id] && viz[this.site.id].from) {
      this.query = Object.assign(viz[this.site.id]);
      if (!this.query.yearCompareQuery) {
        this.query.yearCompareQuery = Object.assign(YEAR_COMPARE_QUERY_DEFAULT);
      } else if(this.query.yearCompareQuery && (typeof this.query.yearCompareQuery.from == 'string' || typeof this.query.yearCompareQuery.to == 'string')){
        this.query.yearCompareQuery.from = {};
        this.query.yearCompareQuery.to = {};
      }

      this.queryDisableUpdate = false;

      if(this.menu == {}){
        for(let year in this.query.yearCompareQuery.years){
          this.menu['f_' + year] = false;
          this.menu['t_' + year] = false;
        }
      }

      if(this.query.yearCompareQuery.years){
        let index = 0;

        let minDate = new Date(1970, 11, 31);
        let maxDate = new Date(1970, 1, 1);

        this.query.yearCompareQuery.update = 0;

        for (let year of this.query.yearCompareQuery.years){
          if(typeof this.query.yearCompareQuery.from[year] == 'undefined' || this.query.yearCompareQuery.from[year] == null){
            this.query.yearCompareQuery.from[year] = year + "-01-01";
          }

          if(typeof this.query.yearCompareQuery.to[year] == 'undefined' || this.query.yearCompareQuery.to[year] == null){
            this.query.yearCompareQuery.to[year] = year + "-12-29";
          }

          if(typeof this.query.yearCompareQuery.uniqueYear == 'undefined'){
            this.query.yearCompareQuery.uniqueYear = {year: 1970, count: 1};
          }

          let startDateStr = this.query.yearCompareQuery.from[year].split('-');
          let endDateStr = this.query.yearCompareQuery.to[year].split('-');

          let realStartDate = new Date(1970, startDateStr[1]-1, startDateStr[2]);
          let realEndDate = new Date(1970, endDateStr[1]-1, endDateStr[2]);

          if(realStartDate < minDate){
            minDate = realStartDate;
          }

          if(realEndDate > maxDate){
            maxDate = realEndDate;
          }

          this.query.yearCompareQuery.minDate = {mn: minDate.getFullYear() + '-' + ('0' + (minDate.getMonth() == 0 ? 1 : minDate.getMonth())).slice(-2) + '-' + ('0' + minDate.getDate()).slice(-2), year: 1970};
          this.query.yearCompareQuery.maxDate = {mx: maxDate.getFullYear() + '-' + ('0' + (maxDate.getMonth() + 1)).slice(-2) + '-' + ('0' + maxDate.getDate()).slice(-2), year: 1970};

          index++;
        }

        if(!this.query.yearCompareQuery.hasOwnProperty("fromUnique")){
          this.query.yearCompareQuery.fromUnique = {};
        }

        if(!this.query.yearCompareQuery.hasOwnProperty("toUnique")){
          this.query.yearCompareQuery.toUnique = {};
        }

        for(let id = 1; id <= this.query.yearCompareQuery.uniqueYear.count; id++){

          let realIndex = this.query.yearCompareQuery.uniqueYear.year + "_" + id;

          if(!(realIndex in this.query.yearCompareQuery.fromUnique)){
            this.query.yearCompareQuery.fromUnique[realIndex] = this.query.yearCompareQuery.uniqueYear.year + "-01-01";
          }

          if(!(realIndex in this.query.yearCompareQuery.toUnique)){
            this.query.yearCompareQuery.toUnique[realIndex] = this.query.yearCompareQuery.uniqueYear.year + "-12-29";
          }
          
          if(typeof this.menu['fu_' + realIndex] == 'undefined'){
            this.menu['fu_' + realIndex] = false;
          }
          
          if(typeof this.menu['tu_' + realIndex] == 'undefined'){
            this.menu['tu_' + realIndex] = false;
          }
        }

        //console.log(this.query.yearCompareQuery);

        const filteredFrom = {};
        for (const key in this.query.yearCompareQuery.from) {
          if (this.query.yearCompareQuery.years.includes(parseInt(key)) || this.query.yearCompareQuery.years.includes(key)) {
            filteredFrom[key] = this.query.yearCompareQuery.from[key];
          }
        }

        const filteredTo = {};
        for (const key in this.query.yearCompareQuery.to) {
          if (this.query.yearCompareQuery.years.includes(parseInt(key)) || this.query.yearCompareQuery.years.includes(key)) {
            filteredTo[key] = this.query.yearCompareQuery.to[key];
          }
        }

        this.query.yearCompareQuery.from = filteredFrom;
        this.query.yearCompareQuery.to = filteredTo;
      }
    }

    if (!this.query.timerange || !Array.isArray(this.query.timerange)) {
      this.query.timerange = TIMERANGE_DEFAULT;
    }
    this.timerange = [];
    this.timerange[0] = this.query.timerange[0];
    this.timerange[1] = this.query.timerange[1];


    
    // this.updateVisualizations();
    // this.filtersData.parcelles = this.getParcelles();
    if (this.site.type === "Domaine") {
      this.getParcelles();
      this.getCepages();
    }
    if (this.site.is_allow_nearest_years) {
      this.getCepages();
    }
    this.resetEmptyVisualization();
  },
  updated() {},
  computed: {
    parsedYears(){
      return this.query.yearCompareQuery.update;
    },
    fromYearCompareQueryToDisp() {
      let yearsConverted = [];

      for(let year of this.query.yearCompareQuery.years){
        if(typeof this.query.yearCompareQuery.to[year] != 'undefined'){
          yearsConverted[year] = convertDatePickerToDDMMstr(this.query.yearCompareQuery.to[year]);
        }
      }
      return yearsConverted;
    },
    fromYearCompareQueryFromDisp() {

      let yearsConverted = [];

      for(let year of this.query.yearCompareQuery.years){
        if(typeof this.query.yearCompareQuery.from[year] != 'undefined'){
          yearsConverted[year] = convertDatePickerToDDMMstr(this.query.yearCompareQuery.from[year]);
        }
      }
      return yearsConverted;
    },
    fromYearCompareQueryToUniqueDisp() {
      let yearsConverted = [];

      for(let id = 1; id <= this.query.yearCompareQuery.uniqueYear.count; id++){
        if(typeof this.query.yearCompareQuery.toUnique[this.query.yearCompareQuery.uniqueYear.year + '_' + id] != 'undefined'){
          yearsConverted[this.query.yearCompareQuery.uniqueYear.year + '_' + id] = convertDatePickerToDDMMstr(this.query.yearCompareQuery.toUnique[this.query.yearCompareQuery.uniqueYear.year + '_' + id]);
        }
      }
      return yearsConverted;
    },
    fromYearCompareQueryFromUniqueDisp() {

      let yearsConverted = [];

      for(let id = 1; id <= this.query.yearCompareQuery.uniqueYear.count; id++){
        if(typeof this.query.yearCompareQuery.fromUnique[this.query.yearCompareQuery.uniqueYear.year + '_' + id] != 'undefined'){
          yearsConverted[this.query.yearCompareQuery.uniqueYear.year + '_' + id] = convertDatePickerToDDMMstr(this.query.yearCompareQuery.fromUnique[this.query.yearCompareQuery.uniqueYear.year + '_' + id]);
        }
      }
      return yearsConverted;
    },
    yearCompareQueryFrom() {
      return this.query.yearCompareQuery.from;
    },
    yearCompareQueryTo() {
      return this.query.yearCompareQuery.to;
    },
  },

  watch: {
    query: {
      handler() {
        this.saveUserAndVisualizations();
      },
      deep: true,
    },
    parsedYears(){

      if(this.query.yearCompareQuery.update === 1){
        let index = 0;

        let minDate = new Date(1970, 11, 31);
        let maxDate = new Date(1970, 1, 1);

        this.query.yearCompareQuery.update = 0;

        /*if(!this.query.yearCompareQuery.hasOwnProperty("fromUnique")){
          this.query.yearCompareQuery.fromUnique = {};
        }

        if(!this.query.yearCompareQuery.hasOwnProperty("toUnique")){
          this.query.yearCompareQuery.toUnique = {};
        }*/

        for (let year of this.query.yearCompareQuery.years){
          if(typeof this.query.yearCompareQuery.from[year] == 'undefined' || this.query.yearCompareQuery.from[year] == null){
            this.query.yearCompareQuery.from[year] = year + "-01-01";
          }

          if(typeof this.query.yearCompareQuery.to[year] == 'undefined' || this.query.yearCompareQuery.to[year] == null){
            this.query.yearCompareQuery.to[year] = year + "-12-29";
          }

          if(typeof this.query.yearCompareQuery.uniqueYear == 'undefined'){
            this.query.yearCompareQuery.uniqueYear = {year: 1970, count: 1};
          }

          let startDateStr = this.query.yearCompareQuery.from[year].split('-');
          let endDateStr = this.query.yearCompareQuery.to[year].split('-');

          let realStartDate = new Date(1970, startDateStr[1] - 1, startDateStr[2]);
          let realEndDate = new Date(1970, endDateStr[1] - 1, endDateStr[2]);

          if(realStartDate < minDate){
            minDate = realStartDate;
          }

          if(realEndDate > maxDate){
            maxDate = realEndDate;
          }

          this.query.yearCompareQuery.minDate = {mn: minDate.getFullYear() + '-' + ('0' + (minDate.getMonth() == 0 ? 1 : minDate.getMonth())).slice(-2) + '-' + ('0' + minDate.getDate()).slice(-2), year: 1970};
          this.query.yearCompareQuery.maxDate = {mx: maxDate.getFullYear() + '-' + ('0' + (maxDate.getMonth() + 1)).slice(-2) + '-' + ('0' + maxDate.getDate()).slice(-2), year: 1970};

          index++;
        }

        for(let id = 1; id <= this.query.yearCompareQuery.uniqueYear.count; id++){

          let realIndex = this.query.yearCompareQuery.uniqueYear.year + "_" + id;

          if(!(realIndex in this.query.yearCompareQuery.fromUnique)){
            this.query.yearCompareQuery.fromUnique[realIndex] = this.query.yearCompareQuery.uniqueYear.year + "-01-01";
          }

          if(!(realIndex in this.query.yearCompareQuery.toUnique)){
            this.query.yearCompareQuery.toUnique[realIndex] = this.query.yearCompareQuery.uniqueYear.year + "-12-29";
          }

          if(typeof this.menu['fu_' + realIndex] == 'undefined'){
            this.menu['fu_' + realIndex] = false;
          }

          if(typeof this.menu['tu_' + realIndex] == 'undefined'){
            this.menu['tu_' + realIndex] = false;
          }
        }

        //console.log(this.query.yearCompareQuery.minDate);

        const filteredFrom = {};
        for (const key in this.query.yearCompareQuery.from) {
          if (this.query.yearCompareQuery.years.includes(parseInt(key)) || this.query.yearCompareQuery.years.includes(key)) {
            filteredFrom[key] = this.query.yearCompareQuery.from[key];
          }
        }

        const filteredTo = {};
        for (const key in this.query.yearCompareQuery.to) {
          if (this.query.yearCompareQuery.years.includes(parseInt(key)) || this.query.yearCompareQuery.years.includes(key)) {
            filteredTo[key] = this.query.yearCompareQuery.to[key];
          }
        }

        this.query.yearCompareQuery.from = filteredFrom;
        this.query.yearCompareQuery.to = filteredTo;
      }

    },
    yearCompareQueryFrom() {
      this.nearestYears = {};
    },
    yearCompareQueryTo() {
      this.nearestYears = {};
    },
    nearestYearQuery :{
       handler() {
         this.nearestYears = {};
      },
      deep: true,
    },
    filters: {
      handler() {
        this.saveUserAndVisualizations();
      },
      deep: true,
    },
  },

  methods: {
    returnCurrentYearCQ(year){
      let newYearCompareQuery = {to: {}, from: {}, years: [year]};
      newYearCompareQuery.from[year] = this.query.yearCompareQuery.from[year];
      newYearCompareQuery.to[year] = this.query.yearCompareQuery.to[year];
      newYearCompareQuery.years = [year];

      return newYearCompareQuery;
    },
    returnDate(dateStr) {
      return new Date(dateStr);
    },
    getDaysBetweenDates(startDate, endDate) {
      const oneDay = 1000*60*60*24; // milliseconds in a day
      // Converting "YYYY-DD-MM" to "YYYY-MM-DD"
      const start = this.returnDate(startDate);
      const end = this.returnDate(endDate);
      return Math.round(Math.abs((end - start) / oneDay));
    },
    addDaysToDate(date, days) {
      let result = this.returnDate(date);
      result.setDate(result.getDate() + days);
      return result.getFullYear() + '-' + 
           ('0' + (result.getMonth() + 1)).slice(-2) + '-' + 
           ('0' + result.getDate()).slice(-2);
    },
    resetEmptyVisualization() {
      this.emptyVisualization = {
        name: "Graph",
        component: "",
        sensors: "",
        events: [],
        axes: { axes: [] },
      };
    },
    getDate: function () {
      return new Date().toLocaleDateString();
    },
    getTime: function () {
      return new Date().toLocaleTimeString();
    },
    convertDatePickerToDDMMstrInternal: function(datestr){
      return convertDatePickerToDDMMstr(datestr);
    },
    printImage: function () {
      let intervalFormat;

      if( this.query.interval == 'day'){
        intervalFormat = 'jours';
      }
      else if (this.query.interval == 'minute'){
        intervalFormat = 'minutes';
      }
      else if (this.query.interval == 'data'){
        intervalFormat = 'données';
      }
      else if (this.query.interval == 'hour'){
        intervalFormat = 'heures';
      }
      else if (this.query.interval == 'week'){
        intervalFormat = 'semaines';
      }
      else if (this.query.interval == 'month'){
        intervalFormat = 'mois';
      }
      else if (this.query.interval == 'year'){
        intervalFormat = 'années';
      }
      // récup. de l'élément <canvas>
      const canvas = document.getElementsByTagName("canvas");
      const graphName = document.getElementById('graphName').innerHTML;
      let nameSite = this.site.complete_name;
      // récup. des données de l'image du <canvas>
      const dataImage = canvas[0].toDataURL("image/png");
      // ouverture d'une nouvelle fenêtre
      const fen = window.open();
      // ouverture du document pour écriture
      fen.document.open();
      // insére un élément "<img> avec l'attribut src contenant les données de l'image
      fen.document.write('<h1>' + document.title  + '</h1>');
      fen.document.write('<h2>' + this.site.complete_name  + '</h2>');
      fen.document.write("<h3> Titre du graphique : " + graphName + "</h3>");
      if( intervalFormat == 'jours' || intervalFormat == 'semaines' || intervalFormat == 'mois' || intervalFormat == 'années') {
        fen.document.write("<p>Date de début : " + this.query.from + " " + " Date de fin : " + this.query.to + " " + " intervalle : " + intervalFormat + "</p>");
      }
      else{
        fen.document.write("<p>Date de recherche : " + this.query.from + " " + " intervalle : " + intervalFormat + "</p>");
      }
      fen.document.write("Date d'impression : le " + this.getDate() + " à " + this.getTime());
      fen.document.write("<br>");
      fen.document.write("<br>");
      fen.document.write("<br>");
      fen.document.write("<img src='" + dataImage + "'>");
      // fermeture du document
      fen.document.close();
      // lance l'impression dès que chargé
      fen.onload = setTimeout(function() {
        fen.print();
        fen.close();
      }, 100);
    },
    updateTimeRange() {
      // this.query.timerange[0] = this.timerange[0];
      // this.query.timerange[1] = this.timerange[1];
      this.query.timerange = this.timerange;
    },
    isIntervalAllowEndDate() {
      return (
        this.query.interval != "data" &&
        this.query.interval != "hour" &&
        this.query.interval != "minute"
      );
    },
    getFiltreLabel(filterName) {
      return "Filtre sur " + filterName;
    },
    getParcelles() {
      this.filtersData.parcelles = [];
      let elasticQuery = {
        _source: {
          excludes: ["fileContent"],
        },
      };

      //TODO check childs !
      let children_site_ids = Object.keys(this.childIdsToName);

      let elasticQueryMust = buildQueryMust(this.site.id, children_site_ids);
      let elasticQueryAggs = {
        "Parcelle_name.keyword": {
          terms: {
            field: "Parcelle_name.keyword",
            size: 10000,
          },
        },
        "Parcelle.keyword": {
          terms: {
            field: "Parcelle.keyword",
            size: 10000,
          },
        },
      };
      elasticQuery = {
        ...elasticQuery,
        query: { bool: { must: elasticQueryMust } },
        aggs: elasticQueryAggs,
      };

      // console.log("elastic query", elasticQuery);

      this.$store
        .dispatch("elasticSearchRequest", {
          index: this.elastic_winery_mesures_index,
          body: elasticQuery,
          size: 1000000,
          site: this.site.id,
        })
        .then((result) => {
          // console.log("Got datas", result.data);
          // aggregations" : {
          //     "Parcelle_name.keyword" : {
          //       "doc_count_error_upper_bound" : 0,
          //       "sum_other_doc_count" : 0,
          //       "buckets :[]
          if (result.data.aggregations["Parcelle_name.keyword"].buckets.length > 0) {
            for (let pcount of result.data.aggregations["Parcelle_name.keyword"].buckets)
              this.filtersData.parcelles.push(pcount.key);
          } else if (result.data.aggregations["Parcelle.keyword"].buckets.length > 0) {
            for (let pcount of result.data.aggregations["Parcelle.keyword"].buckets)
              this.filtersData.parcelles.push(pcount.key);
          }
          let collator = new Intl.Collator(undefined, {
            numeric: true,
            sensitivity: "base",
          });
          this.filtersData.parcelles = this.filtersData.parcelles.sort(collator.compare);
          // this.listReady = true;
        })
        .catch((err) => {
          this.$store.commit("setSnack", {
            type: "red",
            err,
          });
          // this.listReady = true;
        });
    },

    getCepages() {
      this.filtersData.cepages = [];
      let elasticQuery = {
        _source: {
          excludes: ["fileContent"],
        },
      };

      let children_site_ids = Object.keys(this.childIdsToName);

      let elasticQueryMust = buildQueryMust(this.site.id, children_site_ids);
      let elasticQueryAggs = {
        "Cepage.keyword": {
          terms: {
            field: "Cepage.keyword",
            size: 10000,
          },
        },
      };
      elasticQuery = {
        ...elasticQuery,
        query: { bool: { must: elasticQueryMust } },
        aggs: elasticQueryAggs,
      };

      this.$store
        .dispatch("elasticSearchRequest", {
          index: this.elastic_winery_mesures_index,
          body: elasticQuery,
          size: 1000000,
          site: this.site.id,
        })
        .then((result) => {
          if (result.data.aggregations["Cepage.keyword"].buckets.length > 0) {
            for (let pcount of result.data.aggregations["Cepage.keyword"].buckets)
              this.filtersData.cepages.push(pcount.key);
          }
        })
        .catch((err) => {
          this.$store.commit("setSnack", {
            type: "red",
            err,
          });
        });
    },

    saveUserAndVisualizations() {
      let user = this.$store.state.profile.user;
      let visualizations;
      if (user.visualizations) {
        visualizations = user.visualizations;
      } else {
        visualizations = {};
      }

      if(typeof visualizations != 'object') {
        visualizations = {};
      }

      Object.defineProperties(visualizations, {
        query: {
          value: this.query,
          writable: true,
        }
      })

      visualizations[this.site.id] = {... this.query};

      //let queryStr = JSON.stringify(visualizations, null, 2);
      //console.log("VIZ X: " + queryStr);

      user.visualizations = visualizations;

      let data = Object.assign({}, user);
      if (user.clients) data.clients = user.clients.map((c) => c.id);
      if (user.sites) data.sites = user.sites.map((s) => s.id);
      if (user.users) data.users = user.users.map((u) => u.id);
      let request = "userEditRequest";
      let queries = { id: this.$store.state.profile.user.id, datas: data };

      this.$store
        .dispatch(request, queries)
        .then((user) => {
          console.log("successfully saved visualizations params for user : " + user);
          this.updateUser();
        })
        .catch((err) => {
          // this.loading = false;

          console.log("err: " + err);

          if (err.response.status !== 403)
            this.$store.commit("setSnack", {
              type: "red",
              msg: err.response.data,
            });
        });
    },
    updateUser() {
      return this.$store.dispatch("profileRequest");
    },

    onVisualizationCreated() {
      this.createVisualizationModal = false;
      this.onVisualizationChanged();
      this.resetEmptyVisualization();
    },
    onVisualizationUpdated() {
      this.editVisualizationModal = false;
      this.onVisualizationChanged();
    },
    onVisualizationChanged() {
      this.ready = false;
      this.updateVisualizations();
    },
    updateVisualizations() {
      this.$emit("visualizationsChanged");
      this.ready = true;
    },
    onVisualizationCanceled() {
      this.createVisualizationModal = false;
      this.editVisualizationModal = false;
      this.resetEmptyVisualization();
    },
    deleteVisualization(visualization) {
      if (confirm("Êtes-vous sûr de vouloir supprimer ce graphique ?")) {
        this.$store
          .dispatch("visualizationDeleteRequest", { id: visualization.id })
          .then((result) => {
            this.$store.commit("setSnack", {
              type: "success",
              msg: "Graphique supprimé !",
            });
            let index = this.site.visualizations.indexOf(visualization);
            if (index > -1 && index < this.site.visualizations.length)
              this.site.visualizations.splice(index, 1);
          })
          .catch((err) => {
            this.$store.commit("setSnack", {
              type: "red",
              msg: err.response.data,
            });
          });
      }
    },
    canComputeNearestYears() {
      return (
        this.nearestYearQuery.hasPrecipitations ||
        this.nearestYearQuery.hasTmax ||
        this.nearestYearQuery.hasTmoy10 ||
        this.nearestYearQuery.events.length > 0
      );
    },

    computeNearestYears() {
      if (
        (this.nearestYearQuery.hasPrecipitations && !this.site.precipitations) ||
        (this.nearestYearQuery.hasTmoy10 && !this.site.tmoy) ||
        (this.nearestYearQuery.hasTmax && !this.site.tmax)
      ) {
        this.$store.commit("setSnack", {
          type: "red",
          msg:
            "Veuillez définir les mesures correspondantes aux précipitations, température maximum et température minimum au niveau des propriétés du site.",
        });
        return;
      }
      this.loadingNearestYears = true;
      let startDay = this.query.yearCompareQuery.minDate.mn.substring(5);
      let endDay = this.query.yearCompareQuery.maxDate.mx.substring(5);
      let payload = {
        clientId: this.site.client_id,
        site: this.site.id,
        startDay,
        endDay,
        operations: [],
        cepage: this.nearestYearQuery.cepage,
        events: this.nearestYearQuery.events,
      };
      if (this.nearestYearQuery.hasPrecipitations) {
        payload.operations.push({
          op_type_day: "sum",
          op_type_year: "sum",
          field_name: this.site.precipitations,
        });
      }
      if (this.nearestYearQuery.hasTmax) {
        payload.operations.push({
          op_type_day: "max",
          op_type_year: "sum",
          field_name: this.site.tmax,
        });
      }
      if (this.nearestYearQuery.hasTmoy10) {
        payload.operations.push({
          op_type_day: "avg",
          op_type_year: "sum10",
          field_name: this.site.tmoy,
        });
      }
      this.$store
        .dispatch("elasticNearestYearsRequest", payload)
        .then((result) => {
          if (!(this.nearestYearQuery.referenceYear in result.data)) {
            this.$store.commit("setSnack", {
              type: "red",
              msg:
                "L'année de référence " +
                this.nearestYearQuery.referenceYear +
                " n'existe pas dans les données renseignées.",
            });
            this.loadingNearestYears = false;
            return;
          }
          let referenceYearData = result.data[this.nearestYearQuery.referenceYear];
          if (typeof referenceYearData["metrics"] == "string") {
            this.$store.commit("setSnack", {
              type: "red",
              msg:
                "L'année de référence " +
                this.nearestYearQuery.referenceYear +
                " ne contient pas les données suffisantes.",
            });
            this.loadingNearestYears = false;
            return;
          }
          let Precipitations = [];
          let Tsum10 = [];
          let Tmaxsum = [];
          for (let year of Object.keys(result.data)) {
            // if (year == this.nearestYearQuery.referenceYear) continue;
            let yearlyData = result.data[year];
            if (typeof yearlyData["metrics"] == "string") continue;
            Precipitations.push({
              year: year,
              value: Math.round(yearlyData["metrics"][this.site.precipitations]),
            });
            Tsum10.push({
              year: year,
              value: Math.round(yearlyData["metrics"][this.site.tmoy]),
            });
            Tmaxsum.push({
              year: year,
              value: Math.round(yearlyData["metrics"][this.site.tmax]),
            });
          }

          function sortNearestYearsTable(table, referenceValue, referenceYear) {
            table.sort((a, b) => {
              let diff =
                Math.abs(a.value - referenceValue) - Math.abs(b.value - referenceValue);
              if (diff == 0) {
                return (
                  Math.abs(a.year - referenceYear) - Math.abs(b.year - referenceYear)
                );
              }
              return diff;
            });
          }

          let referenceYearPrecipitations =
            referenceYearData["metrics"][this.site.precipitations];
          sortNearestYearsTable(
            Precipitations,
            referenceYearPrecipitations,
            this.nearestYearQuery.referenceYear
          );

          let referenceYearTsum10 = referenceYearData["metrics"][this.site.tmoy];
          sortNearestYearsTable(
            Tsum10,
            referenceYearTsum10,
            this.nearestYearQuery.referenceYear
          );

          let referenceYearTmaxsum = referenceYearData["metrics"][this.site.tmax];
          sortNearestYearsTable(
            Tmaxsum,
            referenceYearTmaxsum,
            this.nearestYearQuery.referenceYear
          );

          let nbElementTableDisplayed = 4;
          Precipitations = Precipitations.slice(0, nbElementTableDisplayed);
          Tsum10 = Tsum10.slice(0, nbElementTableDisplayed);
          Tmaxsum = Tmaxsum.slice(0, nbElementTableDisplayed);

          function compareDate(d1, d2) {
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const firstDate = new Date(d1);
            const secondDate = new Date(d2);

            return Math.round(Math.abs((firstDate - secondDate) / oneDay));
          }
          function compareEventsDate(ed1, ed2) {
            const d1 = new Date(ed1);
            const d2 = new Date(ed2);

            const d10 = new Date(d1.getFullYear(), 0, 1);
            const d20 = new Date(d2.getFullYear(), 0, 1);

            const c1 = compareDate(d1, d10);
            const c2 = compareDate(d2, d20);

            //fix year of dates to compare only the day of year
            return Math.abs(c1 - c2);
          }
          let events = {};
          if (typeof referenceYearData["events"] == "string") {
            let message = "";
            if (referenceYearData["events"] == "Error : no events") {
              message =
                "L'année de référence " +
                this.nearestYearQuery.referenceYear +
                " ne contient aucun événement.";
            } else if (referenceYearData["events"] == "Error : more than 1 events") {
              message =
                "L'année de référence " +
                this.nearestYearQuery.referenceYear +
                " contient plusieurs dates pour le même événement. (veuillez choisir un cépage de référence)";
            }
            this.$store.commit("setSnack", {
              type: "red",
              msg: message,
            });
          } else if (
            this.nearestYearQuery.events &&
            this.nearestYearQuery.events.length > 0
          ) {
            let eventsMissing = [];
            for (let event of this.nearestYearQuery.events) {
              if (referenceYearData.events[event] != null) {
                events[event] = [];
              } else {
                eventsMissing.push(event);
              }
            }
            let message = "";
            if (eventsMissing.length == 1) {
              message =
                "L'événement " +
                eventsMissing +
                " n'existe pas dans l'année de référence.";
            } else if (eventsMissing.length > 1) {
              message =
                "Les événements " +
                eventsMissing +
                " n'existent pas dans l'année de référence.";
            }
            if (eventsMissing.length > 0) {
              this.$store.commit("setSnack", {
                type: "red",
                msg: message,
              });
            }

            for (let year of Object.keys(result.data)) {
              let yearlyData = result.data[year];
              if (typeof yearlyData["events"] == "string") continue;
              for (let event of Object.keys(yearlyData["events"])) {
                if (yearlyData["events"][event] != null && event in events) {
                  events[event].push({
                    year: year,
                    value: compareEventsDate(
                      yearlyData["events"][event],
                      referenceYearData["events"][event]
                    ),
                  });
                }
              }
            }
            for (let event of Object.keys(events)) {
              events[event].sort((a, b) => {
                let diff = a.value - b.value;
                if (diff == 0) {
                  return (
                    Math.abs(a.year - this.nearestYearQuery.referenceYear) -
                    Math.abs(b.year - this.nearestYearQuery.referenceYear)
                  );
                }
                return diff;
              });

              events[event] = events[event].slice(0, 4);
            }
          }

          this.nearestYears = {};
          if (this.nearestYearQuery.events && this.nearestYearQuery.events.length > 0) {
            this.nearestYears = { events };
          }
          if (this.nearestYearQuery.hasPrecipitations) {
            this.nearestYears = { ...this.nearestYears, Precipitations };
          }
          if (this.nearestYearQuery.hasTmax) {
            this.nearestYears = { ...this.nearestYears, Tmaxsum };
          }
          if (this.nearestYearQuery.hasTmoy10) {
            this.nearestYears = { ...this.nearestYears, Tsum10 };
          }
          this.loadingNearestYears = false;
        })
        .catch((err) => {
          this.loadingNearestYears = false;
          console.log(err);
          this.$store.commit("setSnack", {
            type: "red",
            msg: err,
          });
        });
    },
  },
};
</script>
<style>
#slide {
  width: 100%;
}
</style>
